import React, { useState, useEffect } from 'react';
import { Card, CardDeck, Container, Row, Col, Modal, Image, Button, Table } from "react-bootstrap";

import { API_URL, DOMAIN_URL } from "./../../global";

const axios = require("axios");

function TopProducts(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [openModalProductId, setOpenModalProductId] = useState(false);
    const [openModalProductName, setOpenModalProductName] = useState(false);
    const [openModalProductImage, setOpenModalProductImage] = useState(false);
    const [openModalProductDescription, setOpenModalProductDescription] = useState(false);


    const [products, setProducts] = useState(false);
    const [properties, setProperties] = useState(false);

    function fetchProductData() {
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: `select * from product where isTrending=1;`,
        };

        axios
            .post(API_URL, data)
            .then((res) => {
                setProducts(res["data"]);
            })
            .catch((err) => {
                console.log("err: ", err);
            });
    }

    function fetchPropertyTable() {
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: `select * from property;`,
        };

        axios
            .post(API_URL, data)
            .then((res) => {
                setProperties(res["data"]);
            })
            .catch((err) => {
                console.log("err: ", err);
            });
    }
    useEffect(() => {
        fetchProductData();
        fetchPropertyTable();
    }, []);

    function renderProducts() {
        if (products) {
            return products.map((product) => {
                return (
                    <Col md="4" className="mb-4">
                        <Card style={{ boxShadow: '0px 5px 10px -5px', height: '100%' }}>
                            <Card.Img variant="top" src={DOMAIN_URL + product.imageURL} width="70" height="200" />
                            <Card.Body style={{ backgroundColor: '#f7fffc' }}>
                                <Card.Title>{product.productName}</Card.Title>
                                <Card.Text>
                                    {product.description}
                                </Card.Text>
                                <Button variant="outline-success" size="sm" onClick={
                                    () => {
                                        setOpenModalProductId(product.productId);
                                        setOpenModalProductImage(product.imageURL);
                                        setOpenModalProductName(product.productName);
                                        setOpenModalProductDescription(product.description);
                                        handleShow();
                                    }
                                }>
                                    View More
                            </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                );
            });
        }
    }

    function renderProperties(productId) {
        if (properties) {
            return properties.map((property) => {
                if (property.productId === productId) {
                    return (
                        <tr>
                            <td>
                                {property.name}
                            </td>
                            <td>
                                {property.value}
                            </td>
                        </tr>
                    )
                }
                return null;
            })
        }
    }

    return (
        <div>
            <div className="py-5">
                <h1 className="w-100 text-center">TopProducts</h1>
            </div>
            <Container className="mx-4 m-auto pb-5">
                <Row className="mt-3">
                    <CardDeck className="w-100">

                        {renderProducts()}

                    </CardDeck>
                </Row>
                <Row className="mt-5">

                </Row>
            </Container>

            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Product Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={DOMAIN_URL + openModalProductImage} fluid className="w-100" alt="product 1" />
                    <hr />
                    <h2>{openModalProductName}</h2>
                    <p>
                        {openModalProductDescription}
                    </p>
                    <hr />
                    <div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>
                                        Property name
                                    </th>
                                    <th>
                                        Value
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    renderProperties(openModalProductId)
                                }
                            </tbody>


                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default TopProducts;

