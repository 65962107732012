import React, { useState, useEffect } from "react";
import { Jumbotron, Container, Row, Col, Image } from "react-bootstrap";

import { API_URL, DOMAIN_URL } from "./../global";
const axios = require("axios");

function Events() {


    const [events, setEvents] = useState(false);

    function fetchEvents() {
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: `select * from event order by id desc;`,
        };

        axios
            .post(API_URL, data)
            .then((res) => {
                setEvents(res["data"]);
            })
            .catch((err) => {
                console.log("err: ", err);
            });
    }

    function renderEvents() {
        if (events) {
            return events.map((event) => {
                return (
                    <Jumbotron fluid className="p-2 bg-white" style={{ boxShadow: '5px 5px 20px' }}>
                        <Container className="m-0">
                            <Row className="p-0">
                                <Col md="6" sm="12" className="p-0">
                                    <Image src={DOMAIN_URL + event.imageUrl} fluid
                                    />
                                </Col>
                                <Col>
                                    <h3>{event.eventName}</h3>
                                    <h6>{event.date}</h6>
                                    <hr />
                                    <p>
                                        {event.description}
                                    </p>

                                </Col>
                            </Row>
                        </Container>
                    </Jumbotron>
                );
            });
        }
    }

    useEffect(() => {
        fetchEvents();
    }, []);

    return (
        <div className="mb-5">
            <div className="py-4" style={{ backgroundColor: '#14ff001f' }}>
                <h1 className="w-100 text-center">Events</h1>
            </div>
            <div className="mt-5 mx-4">
                {renderEvents()}
            </div>
        </div>
    );
}

export default Events;
