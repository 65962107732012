import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Jumbotron, Row, Col, Button } from "react-bootstrap";
import LaunchIcon from '@material-ui/icons/Launch';

import Image from "react-bootstrap/Image";
import "./../../App.css";
import { API_URL, DOMAIN_URL } from "./../../global";

const axios = require("axios");

function Categories() {

    const [categories, setCategories] = useState(false);

    useEffect(() => {
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: "select * from category",
        };

        axios
            .post(API_URL, data)
            .then((res) => {
                setCategories(res["data"]);
            })
            .catch((err) => {
                console.log("err: ", err);
            });
    }, []);

    function renderCategories() {
        if (categories) {
            return categories.map((category) => {
                return (
                    <Jumbotron className=" mt-5 p-0" style={{backgroundColor: "#ffffff"}}> 
                        <Row className="m-0 p-0">
                            <Col md="6">
                                <Image src={DOMAIN_URL + category.imageURL} fluid style={{border: "1px solid #ddf"}}/>
                            </Col>
                            <Col md="6" className="pt-2 pl-2">
                                <h3 class="h3 float-left">{category.categoryName}</h3>
                                <br style={{clear: "both"}}/><hr/>
                                <p className="float-left text-left">
                                    {category.description}
                                </p>
                                
                                <hr style={{clear: "both"}}/>
                                 <Link to={`/productView/${category.id}/${category.categoryName}`}>
                                    <Button className="d-flex" variant="outline-primary">View More &nbsp; <LaunchIcon/></Button>
                                 </Link>
                            </Col> 
                        </Row>
                    </Jumbotron>
                );
            })
        }
    }

    return (
        <div className="py-5" style={{ backgroundColor: '#edfff9' }}>
            <h1 className="w-100 m-auto my-5 py-3 pt-3 text-center">Categories</h1>

            <div className="mt-3 m-4">

                {renderCategories()}

            </div >
        </div >
    );
}

export default Categories;
