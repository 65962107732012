import React, { Component } from "react";

import Slider from "./Slider";
import Testimonial from "./Testimonial";
import Categories from "./Categories";
import TopProducts from "./TopProducts";
import Counter from "./Counter";

export default class Home extends Component {
    render() {
        return ( <
            >
            
            <
            Slider / >
            <
            Counter / >
            <
            Categories / >
            <
            TopProducts / >
            <
            Testimonial / >
            <
            />
        );
    }
}