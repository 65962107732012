import React from "react";
import { HashRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import "./App.css";
import Header from "./components/Header.js";
import Home from "./components/Home/Home.js";
import AboutUs from "./components/AboutUs.js";
import ProductView from "./components/ProductView.js";
import Services from "./components/services.js";
import Gallery from "./components/Gallery.js";
import Events from "./components/Events.js"
import Enquiry from "./components/Enquiry.js";
import Footer from "./components/Footer";


import 'bootstrap/dist/css/bootstrap.min.css';
import Dealers from "./components/Dealers";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <br /> <br /> <br /> 
        <Switch>
          <Route path="/" exact component={withRouter(Home)} />
          <Route path="/productView/:id/:name" component={withRouter(ProductView)} />
          <Route path="/aboutUs" component={withRouter(AboutUs)} />
          <Route path="/services" component={withRouter(Services)} />
          <Route path="/gallery" component={withRouter(Gallery)} />
          <Route path="/events" component={withRouter(Events)} />
          <Route path="/dealers" component={withRouter(Dealers)} />
          <Route path="/enquiry" component={withRouter(Enquiry)} />
        </Switch>
        <Footer />
      </div>

    </Router>
  );
}

export default App;
