
import React from "react";
import { Container, CardDeck, Card } from "react-bootstrap";


import t1 from "./../images/users/1.jpeg"
import t2 from "./../images/users/2.jpeg"
import t3 from "./../images/users/3.jpeg"
import t4 from "./../images/users/4.png"
import t5 from "./../images/users/5.png"
import t6 from "./../images/users/6.png"

import team from "./../images/team.jpg";
import timeline from "./../images/timeline.png";

function AboutUs() {
    return (
        <>
            <div>
                <div className="py-4" style={{ backgroundColor: '#14ff001f' }}>
                    <h1 className="w-100 text-center">Our Team</h1>
                </div>
                <img src={team} className="img-fluid" alt="team" />
            </div>

            <div className="py-5" style={{ backgroundColor: '#58b2f3' }}>
                <h1 className="w-100 text-center mb-5">Our History</h1>
                <img src={timeline} className="img-fluid" alt="history" />
            </div>

            <div className="my-5">
                <h1 className="w-100 text-center pt-5 pb-3">Who we Are?</h1>
                <Container className="mx-4 m-auto">
                    <CardDeck className="mx-md-4 px-md-4 mb-5">
                        <Card style={{ boxShadow: '0px 5px 10px -5px' }}>
                            <Card.Img variant="top" src={t1} height="200" />
                            <Card.Header>
                                <h5>Nitin Nimagre</h5>
                                <h6>Owner</h6>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    Directing Shreeram agro. industries
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card style={{ boxShadow: '0px 5px 10px -5px' }}>
                            <Card.Img variant="top" src={t2} width="70" height="200" />
                            <Card.Header>
                                <h5>Dinesh Nimagre</h5>
                                <h6>Owner</h6>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    Directing Shreeram agro. industries
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card style={{ boxShadow: '0px 5px 10px -5px' }}>
                            <Card.Img variant="top" src={t3} width="70" height="200" />
                            <Card.Header>
                                <h5>Dattatray Shejal</h5>
                                <h6>Managing Director</h6>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    Managing all divisions of Shreeram agro. industries
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                    <CardDeck className="mx-md-4 px-md-4">
                        <Card style={{ boxShadow: '0px 5px 10px -5px' }}>
                            <Card.Img variant="top" src={t4} height="200" />
                            <Card.Header>
                                <h5>Samadhan Narale</h5>
                                <h6>Manager</h6>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    Manager at Shreeram agro industries
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card style={{ boxShadow: '0px 5px 10px -5px' }}>
                            <Card.Img variant="top" src={t5} width="70" height="200" />
                            <Card.Header>
                                <h5>Vaibhav Mane</h5>
                                <h6>Technical Incharge</h6>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    Handling all technical operations
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card style={{ boxShadow: '0px 5px 10px -5px' }}>
                            <Card.Img variant="top" src={t6} width="70" height="200" />
                            <Card.Header><h5>Ganesh Nimagre</h5>
                                <h6>Field Expert</h6></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    Expert in dairy industry & machines
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </Container>
            </div>
        </>
    );
}

export default AboutUs;
