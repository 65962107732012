
import React from "react";
import {Container, CardDeck, Card, Row, Col} from "react-bootstrap";
import CountUp from 'react-countup';

import c1 from "./../../images/counter/1.png";
import c2 from "./../../images/counter/2.png";
import c3 from "./../../images/counter/3.png";
import c4 from "./../../images/counter/4.png";
import c5 from "./../../images/counter/5.png";

function Counter() {
    return (
        <div className="my-5">
            <h1 className="w-100 text-center">Our Statistics</h1>
            <Container className="mx-5 m-auto">
            <Row className="justify-content-center">
                <Col xs={6} sm={2} className="mb-2">
                    <Card className="border border-success h-100">
                        <Card.Body>
                            <Card.Text>
                                <h2 className="w-100 text-center">+<CountUp useEasing={true} start={0} end={5} delay={1} /></h2>
                            </Card.Text>
                            <Card.Text>
                                <h6 className="w-100 text-center">experience</h6>
                            </Card.Text>
                        </Card.Body>
                        <Card.Img variant="bottom" src={c1} />
                    </Card>
                </Col>
                <Col xs={6} sm={2} className="mb-2">
                    <Card className="border border-success h-100">
                        <Card.Body>
                            <Card.Text>
                                <h2 className="w-100 text-center">+<CountUp useEasing={true} start={0} end={5000} delay={1} /></h2>
                            </Card.Text>
                            <Card.Text>
                                <h6 className="w-100 text-center">happy customers</h6>
                            </Card.Text>
                        </Card.Body>
                        <Card.Img variant="bottom" src={c5} />
                    </Card>
                </Col>
                <Col xs={6} sm={2} className="mb-2">
                    <Card className="border border-success h-100">
                        <Card.Body>
                            <Card.Text>
                                <h2 className="w-100 text-center">+<CountUp useEasing={true} start={0} end={400} delay={1} /></h2>
                            </Card.Text>
                            <Card.Text>
                                <h6 className="w-100 text-center">products</h6>
                            </Card.Text>
                        </Card.Body>
                        <Card.Img variant="bottom" src={c2} />
                    </Card>
                </Col>
                <Col xs={6} sm={2} className="mb-2">
                    <Card className="border border-success h-100">
                        <Card.Body>
                            <Card.Text>
                                <h2 className="w-100 text-center"><CountUp useEasing={true} start={0} end={7} delay={1} /></h2>
                            </Card.Text>
                            <Card.Text>
                                <h6 className="w-100 text-center">state presence</h6>
                            </Card.Text>
                        </Card.Body>
                        <Card.Img variant="bottom" src={c3} />
                    </Card>
                </Col>
                <Col xs={6} sm={2} className="mb-2">
                    <Card className="border border-success h-100">
                        <Card.Body>
                            <Card.Text>
                                <h2 className="w-100 text-center">+<CountUp useEasing={true} start={0} end={50} delay={1} /></h2>
                            </Card.Text>
                            <Card.Text>
                                <h6 className="w-100 text-center">city presence</h6>
                            </Card.Text>
                        </Card.Body>
                        <Card.Img variant="bottom" src={c4} />
                    </Card>
                </Col>
            </Row>
            </Container>
        </div>
    );
}

export default Counter;
