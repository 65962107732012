import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Modal, Button } from "react-bootstrap";

import { API_URL, DOMAIN_URL } from "./../global";

const axios = require("axios");

function Gallery() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [gallery, setGallery] = useState(false);
    const [activeImage, setActiveImage] = useState(false);

    function fetchGalleryData() {
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: `select * from gallery;`,
        };

        axios
            .post(API_URL, data)
            .then((res) => {
                setGallery(res["data"]);
                console.log(res["data"]);
            })
            .catch((err) => {
                console.log("err: ", err);
            });
    }

    useEffect(() => {
        fetchGalleryData();
    }, []);


    function renderImages() {
        if (gallery !== false) {
            return gallery.map((image) => {
                return (
                    <Col md="3" className="mb-4">
                        <Card onClick={() => { setActiveImage(DOMAIN_URL + image.imageUrl); handleShow(); }} style={{ boxShadow: '0px 5px 10px -5px' }}>
                            <Card.Img variant="top" src={DOMAIN_URL + image.imageUrl} width="70" height="250" />
                        </Card>
                    </Col>
                );
            });
        }
    }

    return (
        <div className="mb-5">
            <div className="py-4" style={{ backgroundColor: '#14ff001f' }}>
                <h1 className="w-100 text-center">Gallery</h1>
            </div>

            <Container className="mx-4 m-auto">
                <Row className="mt-4">
                    {renderImages()}
                </Row>
            </Container>

            <hr />
            {/* <Container className="mx-4 m-auto">
                <Row>
                    <Col md="4" className="mb-4">
                        <Card onClick={handleShow} style={{ boxShadow: '0px 5px 10px -5px' }}>
                            <ResponsiveEmbed aspectRatio="16by9">
                                <embed src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1" />
                            </ResponsiveEmbed>
                        </Card>
                    </Col>
                    <Col md="4" className="mb-4">
                        <Card onClick={handleShow} style={{ boxShadow: '0px 5px 10px -5px' }}>
                            <ResponsiveEmbed aspectRatio="16by9">
                                <embed src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1" />
                            </ResponsiveEmbed>
                        </Card>
                    </Col>
                    <Col md="4" className="mb-4">
                        <Card onClick={handleShow} style={{ boxShadow: '0px 5px 10px -5px' }}>
                            <ResponsiveEmbed aspectRatio="16by9">
                                <embed src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1" />
                            </ResponsiveEmbed>
                        </Card>
                    </Col>

                </Row>
            </Container> */}


            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Full Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={activeImage} className="w-100" alt="product image" fluid />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Gallery;

