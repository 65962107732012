import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./../App.css";
import {Link} from "react-router-dom";
import { Navbar, NavDropdown, Nav, Form, FormControl, Button, Row, Col } from "react-bootstrap";
import logo from "./../images/logo.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons"

import { API_URL } from "./../global";
const axios = require("axios");

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: null
    }
  }

  fetchCategories() {
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: "select * from category",
    };

    axios
      .post(API_URL, data)
      .then((res) => {
        this.setState({ categories: res["data"] });
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }

  componentWillMount() {
    this.fetchCategories();
  }

  renderCategoryDropdown() {
    if (this.state.categories) {
      return this.state.categories.map((category) => {
        return (
          <Link to ={`/productView/${category.id}/${category.categoryName}`}>
          <li><a className="dropdown-item">  {category.categoryName} </a></li>
          </Link>
        );
      })
    }
  }

  render() {
    return (
      <Row className="d-flex-inline">
        {/* <Col md="2" sm="4" xs="6" className="w-100 m-auto text-center">
          <NavLink to="/">
            <Navbar.Brand>
              <img src={logo} alt="logo" width="100" height="100" className="d-inline-block align-top" />
            </Navbar.Brand>
          </NavLink>
        </Col> */}
        {/* <Col md="10" sm="4" xs="6" className="w-100 m-auto mt-md-3  text-center"> */}

          {/* <Navbar className="App-header m-1 p-0 text-center" bg="white" expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto d-flex justify-content-between">
                <Nav.Link><NavLink to="/" activeClassName="active" style={{ color: 'gray' }}>Home</NavLink></Nav.Link>
                <NavDropdown title="Products" id="basic-nav-dropdown">
                  {this.renderCategoryDropdown()}
                </NavDropdown>
                <Nav.Link><NavLink to="/aboutUs" style={{ color: 'gray' }}>About Us</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/services" style={{ color: 'gray' }}>Services</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/gallery" style={{ color: 'gray' }}>Gallery</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/events" style={{ color: 'gray' }}>Events</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/dealers" style={{ color: 'gray' }}>Dealers</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/enquiry" style={{ color: 'gray' }}>Enquiry</NavLink></Nav.Link>
              </Nav>
              <Form inline className="mx-2">
                <FormControl type="text" placeholder="Search product" className="" size="sm" />
                <Button variant="outline-success" size="sm" ><FontAwesomeIcon icon={faSearch} /></Button>
              </Form>
            </Navbar.Collapse>
          </Navbar> */}
<nav className="navbar navbar-expand-md fixed-top  mb-4">
  <div className="container-fluid">
    <img style={{backgroundColor: 'white', padding: 5}} src={logo} width={80} height={80} />
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <ul className="navbar-nav me-auto mb-4 mb-md-0">
        &nbsp; &nbsp; &nbsp; &nbsp;
        <li className="nav-item">
        <Link to ={`/`}><a className="nav-link" href="index.html">Home</a> </Link>  
        </li>
        &nbsp; &nbsp;
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Products</a>
          <ul className="dropdown-menu" aria-labelledby="dropdown01">
            {this.renderCategoryDropdown()}
          </ul>
        </li>
        &nbsp; &nbsp;
        <li className="nav-item">
        <Link to ={`/aboutUs`}><a className="nav-link" href="index.html">About Us</a> </Link>  
        </li>
        &nbsp; &nbsp;
        <li className="nav-item">
        <Link to ={`/services`}><a className="nav-link" href="index.html">Services</a> </Link>  
        </li>
        &nbsp; &nbsp;
        <li className="nav-item">
        <Link to ={`/gallery`}><a className="nav-link" href="index.html">Gallery</a> </Link>  
        </li>
        &nbsp; &nbsp;
        <li className="nav-item">
        <Link to ={`/events`}><a className="nav-link" href="index.html">Events</a> </Link>  
        </li>
        &nbsp; &nbsp;
        <li className="nav-item">
        <Link to ={`/dealers`}><a className="nav-link" href="index.html">Dealers</a> </Link>  
        </li> &nbsp; &nbsp;
        <li className="nav-item">
        <Link to ={`/enquiry`}><a className="nav-link" href="index.html">Enquiry</a> </Link>  
        </li>
      </ul>
    </div>
  </div>
</nav>

        {/* </Col> */}
      </Row>

    );
  }
}
