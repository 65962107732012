import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { LocationOn } from '@material-ui/icons';
import indiaMap from "./../images/indiaMap.png";

export default function Dealers() {
    return (
        <div>
            <div className="py-md-4" style={{ backgroundColor: '#14ff001f' }}>
                <h1 className="w-100 text-center">Our Dealer's Network</h1>
            </div>
            <Row className="m-4">
                <Col md="4" sm="10">
                    <h2 className="text-center">Our presence in India</h2>
                    <hr />
                    <div>
                        <span className="d-block mb-3">
                            <LocationOn className="d-inline-flex" /> <h5 className="d-inline-flex mb-3 ml-1">
                                Maharashtra
                            </h5>
                        </span>
                        <hr />
                        <span className="d-block mb-3">
                            <LocationOn className="d-inline-flex" /> <h5 className="d-inline-flex mb-3 ml-1 ">
                                Karnataka
                            </h5>
                        </span>
                        <hr />
                        <span className="d-block mb-3">
                            <LocationOn className="d-inline-flex" /> <h5 className="d-inline-flex mb-3 ml-1">
                                Rajasthan
                            </h5>
                        </span>
                        <hr />
                        <span className="d-block mb-3">
                            <LocationOn className="d-inline-flex" /> <h5 className="d-inline-flex mb-3 ml-1">
                                Andhara pradesh
                            </h5>
                        </span>
                        <hr />
                        <span className="d-block mb-3">
                            <LocationOn className="d-inline-flex" /> <h5 className="d-inline-flex mb-3 ml-1">
                                Tamil Nadu
                            </h5>
                        </span>
                        <hr />
                        <span className="d-block mb-3">
                            <LocationOn className="d-inline-flex" /> <h5 className="d-inline-flex mb-3 ml-1">
                                Telengana
                            </h5>
                        </span>
                        <hr />
                        <span className="d-block mb-3">
                            <LocationOn className="d-inline-flex" /> <h5 className="d-inline-flex mb-3 ml-1">
                                Punjab
                            </h5>
                        </span>
                    </div>
                </Col>
                <Col>
                    <Image src={indiaMap} fluid className="w-80" ></Image>
                </Col>
            </Row>
            <hr />
            <div className="m-md-4 mt-5 p-0">
                <h1 className="w-100 text-center">Our presence in Mahatashtra</h1>
                <Row className="w-100 m-auto m-0 mt-1 py-3">
                    <Col md="3" sm="10">
                        <ul>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Latur
                                </h5>
                            </li>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Sangola
                                </h5>
                            </li>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Pune
                                </h5>
                            </li>
                        </ul>
                    </Col>
                    <Col md="3" sm="10">
                        <ul>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Aurangabad
                                </h5>
                            </li>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Kolhapur
                                </h5>
                            </li>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Solapur
                                </h5>
                            </li>
                        </ul>
                    </Col>
                    <Col md="3" sm="10">
                        <ul>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Temburni
                                </h5>
                            </li>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Phaltan
                                </h5>
                            </li>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Sangli
                                </h5>
                            </li>
                        </ul>
                    </Col>
                    <Col md="3" sm="10">
                        <ul>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Karad
                                </h5>
                            </li>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Pandharpur
                                </h5>
                            </li>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Osmanabad
                                </h5>
                            </li>
                            <li>
                                <h5 className="d-inline-flex mb-3 ml-1">
                                    <LocationOn fontSize="sm" className="d-inline-flex mr-1" />
                                    Satara
                                </h5>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

