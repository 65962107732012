import React, { useState, useEffect } from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import "./Test.css";
import "./../../App.css";


import { API_URL, DOMAIN_URL } from "./../../global";
const axios = require("axios");

function Testimonial() {
    const [testimonials, setTestimonials] = useState(false);


    function fetchTestimonials() {
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: `select * from testimonial`,
        };

        axios
            .post(API_URL, data)
            .then((res) => {
                setTestimonials(res["data"]);
            })
            .catch((err) => {
                console.log("err: ", err);
            });
    }

    useEffect(() => {
        fetchTestimonials();
        const script = document.createElement('script');
        script.src = "assets/dist/js/testimonial.js";
        script.async = false;
        document.body.appendChild(script);
        return () => {
            document.body.appendChild(script);
        }

    }, []);


    // function renderTestimonials(limit) {
    //     if (testimonials) {
    //         return testimonials.map((testimonial) => { 
    //             return (

                  
                
    //             );
    //         });
    //     }
    // }

    return (
        // <div className="my-5 py-5" style={{ backgroundColor: '#edf8ff' }}>
        //     <h1 className="my-5 w-100 m-auto text-center">Testimonials</h1>
        //     <Carousel>
        //         <Carousel.Item>
        //             <Row className="m-2 mt-5">
        //                 {renderTestimonials(3)}
        //             </Row>
        //         </Carousel.Item>
        //         <Carousel.Item>
        //             <Row className="m-2 mt-5">
        //                 {renderTestimonials(6)}
        //             </Row>
        //         </Carousel.Item>
        //         <Carousel.Item>
        //             <Row className="m-2 mt-5">
        //                 {renderTestimonials(9)}
        //             </Row>
        //         </Carousel.Item>
        //     </Carousel>
        // </div >
<div className="container-fluid px-3 px-sm-5 my-5 text-center">
  {/* <h4 className="mb-5 font-weight-bold">What Our Client Say</h4>
  <div className="owl-carousel owl-theme">

   
  <div className="item">
        <div className="card border-0 py-3 px-4">
            <div className="row justify-content-center"> <img src="" className="img-fluid profile-pic mb-4 mt-3" /> </div>
            <h6 className="mb-3 mt-2">Marielle Haag</h6>
            <p className="content mb-5 mx-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim.</p>
        </div>
    </div>
   
    
  </div> */}
</div>



    );
}

export default Testimonial;
