import React, { Component } from "react";
import Carousel from 'react-bootstrap/Carousel'
import { Jumbotron, Container, Row, Col, Button } from "react-bootstrap";
import { ArrowRightAlt } from '@material-ui/icons';
import Image from "react-bootstrap/Image";
import product1 from "./../../images/category/1.jpg";
import product2 from "./../../images/category/2.png";
import product3 from "./../../images/category/3.png";
import banner1 from "./../../images/banner-1.jpg"
import { useEffect, useState } from "react";
import "./../../App.css";
import { API_URL, DOMAIN_URL } from "./../../global";
const axios = require("axios");

function Slider() {
    const [Slider, setSlider] = useState(false);
    const [path, setPath] = useState("https://shreeramagroind.com/shreeramagro/admin/");
    useEffect(() => {
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: "select * from offerbanner order by sortOrder",
        };
        
        axios
            .post(API_URL, data)
            .then((res) => {
                setSlider(res["data"]);
            })
            .catch((err) => {
                console.log("err: ", err);
            });
    }, []);

    function renderCategories() {
        if (Slider) {
            return Slider.map((img) => {
                return ( <Carousel.Item>
                    <img className = "d-block w-100"
                    src = { path + img.imageURL }
                    alt = "First slide"
                    />
                    <Carousel.Caption>
                    </Carousel.Caption> 
                </Carousel.Item>
                );
            })
        }
    }
    return ( <Carousel> { renderCategories() } </Carousel>    
    );
}


export default Slider;