import React from 'react';

import { Row, Col, Carousel } from "react-bootstrap";

import banner1 from "./../images/service-1.jpg"
import banner2 from "./../images/service-2.jpg"
import banner3 from "./../images/service-3.jpg"
import { FiberManualRecord } from '@material-ui/icons';

export default function Services(props) {

    return (
        <div className="mb-5">
            <div className="py-4" style={{ backgroundColor: '#14ff001f' }}>
                <h1 className="w-100 text-center">Our Services</h1>
            </div>
            <Row className="m-0 p-0 m-md-2 mt-5">
                <Col md="8" className="mb-5">
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={banner1}
                                alt="First slide"
                                height="500"
                            />
                            <Carousel.Caption>
                                <h3>Professional Workshop</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={banner2}
                                alt="Secon                                <h3>On-Site Service</h3>
                                d slide"
                                height="500"
                            />

                            <Carousel.Caption>
                                <h3>On-Site Service</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={banner3}
                                alt="Third slide"
                                height="500"
                            />

                            <Carousel.Caption>
                                <h3>Professional Technician </h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Col>
                <Col >
                    <h2>Our Key Services</h2>
                    <hr />
                    <div>
                        <span className="d-flex">
                            <FiberManualRecord fontSize="sm" /> <h5 className="mb-3 ml-1">Manufacturing
                            </h5>
                        </span>
                        <hr />
                        <span className="d-flex">
                            <FiberManualRecord fontSize="sm" /> <h5 className="mb-3 ml-1">Distribution
                            </h5>
                        </span>
                        <hr />
                        <span className="d-flex">
                            <FiberManualRecord fontSize="sm" /> <h5 className="mb-3 ml-1">Trading
                        </h5>
                        </span>
                        <hr />
                        <span className="d-flex">
                            <FiberManualRecord fontSize="sm" /> <h5 className="mb-3 ml-1">Supplier
                        </h5>
                        </span>
                        <hr />
                        <span className="d-flex">
                            <FiberManualRecord fontSize="sm" /> <h5 className="mb-3 ml-1">On Field Service
                        </h5>
                        </span>
                    </div>
                </Col>

            </Row>

        </div>
    );
}
