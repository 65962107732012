import React, { useState } from "react";
import "./enquiry.css";
import { Row, Col } from "react-bootstrap";
import swal from 'sweetalert';

import mapbg from "./../images/map_bg.png";
import { API_URL, DOMAIN_URL } from "./../global";

const axios = require("axios");

function Enquiry() {

    const [name, setName] = useState(false);
    const [email, setEmail] = useState(false);
    const [mobileNo, setMobileNo] = useState(false);
    const [message, setMessage] = useState(false);

    function sendEmail() {

        axios
            .get(`${DOMAIN_URL}/email/emailSender.php?emailId=${email}&name=${name}&mobileNo=${mobileNo}&message=${message}`)
            .then((res) => {
            })
            .catch((err) => {
                console.log("err: ", err);
            });
    }

    function saveEnquiry() {
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: `insert into enquiryform(name, mobile, emailId, subject, message, status) values('${name}', '${mobileNo}', 'enquiry message from Shreeram agro website', '${email}', '${message}', 0);`,
        };

        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("enquiry form response: ", res);
                swal("succesful!", "Enquiry message sent succesfully!", "success");

                sendEmail();
            })
            .catch((err) => {
                console.log("enquiry form err: ", err);
            });
    }

    return (
        <div>
            <div className="py-4" style={{ backgroundColor: '#14ff001f' }}>
                <h1 className="w-100 text-center">Contact Us</h1>
            </div>
            <div style={{ backgroundImage: `url(${mapbg})` }}>
                <Row className="py-5 m-0 p-0">
                    <Col md="5" className="m-0 p-0">
                        <div className="container-contact100 pt-0">
                            <div className="contact100-map" id="google_map" data-map-x="40.722047" data-map-y="-73.986422" data-pin="images/icons/map-marker.png" data-scrollwhell="0" data-draggable="1"></div>

                            <div className="wrap-contact100">
                                <div className="contact100-form validate-form">
                                    <span className="contact100-form-title">
                                        Contact Us
			    	                </span>
                                    <div>
                                        <h4>Our address</h4>
                                        <p>
                                            Opposit new court, St. stand road, Kavthemahankal, Dist. Sangli, Maharashtra. <br />PIN Code: 416405
                                        </p>
                                    </div>
                                    <hr />
                                    <h4>Send enquiry form</h4>
                                    <div className="wrap-input100 validate-input" data-validate="Name is required">
                                        <input className="input100" type="text" name="name" placeholder="Full Name" onChange={e => setName(e.target.value)} />
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>

                                    <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                        <input className="input100" type="text" name="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate="Valid subject name is requires">
                                        <input className="input100" type="text" name="mobileNo" placeholder="Mobile No." onChange={e => setMobileNo(e.target.mobileNo)} />
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>

                                    <div className="wrap-input100 validate-input" data-validate="Message is required">
                                        <textarea className="input100" name="message" placeholder="Your Message" onChange={e => setMessage(e.target.value)} ></textarea>
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>

                                    <div className="container-contact100-form-btn">
                                        <button className="contact100-form-btn" onClick={() => { saveEnquiry() }}>
                                            Send
					                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col>
                        <div style={{ border: '1px', borderRadius: '70px' }}>
                            <iframe title="title"
                                width="100%"
                                height="692"
                                frameborder="1"
                                src="https://www.google.com/maps/embed/v1/view?key=AIzaSyAIgJDwqV4eEe344MGLSrivGOPIZJUdu1Q&center=17.014956,74.861155&zoom=18&maptype=satellite" allowfullscreen
                                className="rounded">brbr
                            </iframe>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Enquiry;

